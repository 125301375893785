import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useError } from '../../hooks/useError';

import { Card, CardBody, Row, Col } from 'reactstrap';

import Header from '../../components/header';
import { Attendee } from '../../components/attendee';
import Cart from '../../components/cart';
import Steps from '../../components/steps';
import { Alert } from '../../components/modal';
import Event from '../../components/event';
import { Token } from '../../components/token';
import PromoCode from '../../components/promocode';
import { PixQrCode } from '../../components/pixqrcode';
import PaymentOption from '../../components/paymentOption';
import { Customer } from '../../components/customer';
import * as transactionService from '../../services/transaction';
import Zendesk from '../../components/zendesk';
import { Loading } from '../../components/comon';

import { TransactionContext } from '../../contexts/TransactionContext';

import {
  attendeeDefault,
  transactionDefault,
  customerDefault,
  cctokenDefault,
  transactionResponseDefault,
} from '../../helpers/models';

import { CREDITCARD, FREE, PIX, PIXEXPIRETIME } from '../../helpers/constants';

import { createPaymentToken } from '../../services/cctoken';
import { sendTransaction } from '../../services/transaction';
import { calculate } from '../../services/calculate';
import { emailValidation } from '../../services/emailvalidation';

import Success from '../../components/success';

export default function Payment() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [loadingControl, setLoadingControl] = useState({
    open: false,
    message: 'Carregando',
  });

  const goToTop = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    element.focus();
  };

  const {
    cart,
    nextStep,
    prevStep,
    step,
    remainingTime,
    setRemainingTime,
    cnpjLogin,
    cpfLogin,
    emailLogged,
  } = useContext(TransactionContext);

  const { attendeeError, cctokenError, customerError } = useError();

  const [amount, setAmount] = useState(null);

  const [nextButtonControl, setNextButtonControl] = useState({
    openEmailField: true,
    open: false,
    errorMessage: '',
  });

  const [attendees, setAttendees] = useState([]);
  useEffect(() => {
    try {
      getFirstAttendee();
    } catch (error) {
      console.log('Error getFirstAttendee', error);
    }

    function getFirstAttendee() {
      if (emailLogged && firstAccess.current && attendees[0] && attendees[0].email === '') {
        fillAttendee('email', emailLogged, 0);
        firstAccess.current = false;
        setNextButtonControl({ ...nextButtonControl, openEmailField: false });
      }
    }
  }, [attendees]);
  const firstAccess = useRef(true);

  useEffect(() => {
    firstAccess.current = true;
    checkIfTicketAreFree();
  }, []);

  const checkIfTicketAreFree = async () => {
    if (cart[0]?.tag === '100porcento') {
      setTransaction({
        ...transaction,
        paymentType: FREE,
      });
    }
  }

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const [transaction, setTransaction] = useState(transactionDefault);
  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const [customer, setCustomer] = useState(customerDefault);
  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const [cctoken, setCctoken] = useState(cctokenDefault);
  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const [htmlId, setHtmlId] = useState('');
  const [message, setMessage] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggleModal = () => {
    setIsOpenModal(false);
    setTimeout(() => {
      if (htmlId) {
        goToFieldWithError(htmlId);
      }
    }, 50);
  };
  const openModal = () => setIsOpenModal(true);

  const [disabledBuy, setDisabledBuy] = useState(false);
  const doNothing = () => {
    setDisabledBuy(false);
    return;
  };

  const [paid, setPaid] = useState(false);

  const [transactionResponse, setTransactionResponse] = useState(
    transactionResponseDefault,
  );

  const [errorMessage, setErrorMessage] = useState('');

  const [promoCode, setPromoCode] = useState('');

  const [calc, setCalc] = useState(null);

  const validate = (name, fields, error, model, index = -1) => {
    let response = true;
    for (let field in fields) {
      let validated = error[field](model);
      if (validated === false) {
        const id =
          index > -1 ? `${name}-${index}-${field}` : `${name}-${field}`;
        const msg = `${name}.errors.${field}`;
        setHtmlId(id);
        setMessage(t(msg));
        openModal();
        response = false;
        break;
      }
    }
    return response;
  };

  const handleNext = async (e) => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: 'Carregando' });

      if (step <= attendees.length) {
        const index = step - 1;
        const attendee = attendees[index];
        const attendeeErrorFields = t('attendee.errors', {
          returnObjects: true,
        });
        const validated = validate(
          'attendee',
          attendeeErrorFields,
          attendeeError,
          attendee,
          index,
        );

        if (validated) {
          const data = {
            eventId: transaction.eventId,
            email: attendee.email,
          };

          const emails = attendees.filter(
            (attendee) => attendee.email === data.email,
          );
          const emailAlreadyAdded = emails.length > 1;
          const response = await emailValidation(data);

          if (emailAlreadyAdded || !response.success) {
            setHtmlId(`attendee-${index}-email`);
            setMessage(t('tickets.errors.emailalreadyadded'));
            openModal();
            return;
          } else {
            nextStep();
            goToTop('event-header');
          }
        }
      }
    } catch (error) {
      console.log('Error handleNext', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  };

  const handlePrev = (e) => {
    if (step > 1) {
      prevStep();
      goToTop('event-header');
    } else {
      navigate('/');
    }
  };

  const handlePix = (e) => {
    setAmount(calc ? calc.pix : amount);
    fillTransaction('paymentType', PIX);
  };
  const handleCreditCard = (e) => {
    setAmount(calc ? calc.credit_card[cctoken.installments] : amount);
    fillTransaction('paymentType', CREDITCARD);
  };
  const handlePromoCode = async (e) => {
    e.preventDefault();
    if (promoCode === '') {
      return;
    }
    const data = {
      products: cart.map((product) => {
        return { id: product.id, count: product.count };
      }),
      code: promoCode,
    };
    const response = await calculate(data);
    if (response.success) {
      setCalc(response.data);
      if (response.data.pix > 0 && response.data.pix !== amount) {
        fillTransaction('promoCode', promoCode);

        if (transaction.paymentType === PIX) {
          setAmount(response.data.pix);
        }
        if (transaction.paymentType === CREDITCARD) {
          setAmount(response.data.credit_card[cctoken.installments]);
        }
        if (transaction.paymentType === FREE) {
          fillTransaction('paymentType', PIX);
        }
      }
      if (response.data.pix === 0) {
        fillTransaction('paymentType', FREE);
        fillTransaction('promoCode', promoCode);
        setAmount(response.data.pix);
      }
    }

    if (response.message === 'PROMO_NOT_EXISTS') {
      setMessage('Este cupom não é válido para este tipo de ingresso');
      openModal();
    }
  };
  const handleBuy = async (e) => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: 'Salvando' });

      e.preventDefault();
      setErrorMessage('');
      setDisabledBuy(true);
      if (transaction.paymentType === CREDITCARD) {
        const ccTokenErrorFields = t('cctoken.errors', {
          returnObjects: true,
        });
        const validatedCctoken = validate(
          'cctoken',
          ccTokenErrorFields,
          cctokenError,
          cctoken,
        );
        if (!validatedCctoken) {
          return doNothing();
        }
        const tokenResponse = await createPaymentToken(
          transaction.paymentType,
          cctoken,
        );
        if (tokenResponse?.success) {
          transaction.token = tokenResponse.token;
        } else {
          setErrorMessage(tokenResponse.message);
          return doNothing();
        }
      }

      const customerErrorFields = t('customer.errors', {
        returnObjects: true,
      });

      const validatedCustomer = validate(
        'customer',
        customerErrorFields,
        customerError,
        customer,
      );

      if (!validatedCustomer) {
        return doNothing();
      }

      transaction.customer = customer;
      transaction.attendees = attendees;
      transaction.products = cart.map((product) => {
        return { id: product.id, count: product.count };
      });
      transaction.installments =
        transaction.paymentType === CREDITCARD
          ? parseInt(cctoken.installments)
          : 1;

      const response = await sendTransaction(transaction);
      setTransactionResponse(response);

      if (response.success) {
        if (transaction.paymentType === PIX) {
          setRemainingTime(PIXEXPIRETIME);
        }
        if (
          transaction.paymentType === CREDITCARD ||
          transaction.paymentType === FREE
        ) {
          setPaid(true);
        }
      } else {
        try {
          const errorMessages = {
            ALREADY_BOUGHT: t('tickets.errors.alreadybought'),
            PRODUCT_NOT_AVAILABLE: t('tickets.errors.productnotavailable'),
            PROMO_NOT_AVAILABLE: t('tickets.errors.promonotavailable'),
          };
          const newErrorMessage =
            errorMessages[response?.data?.message] || t('tickets.errors.generic');
          setErrorMessage(newErrorMessage);
        } catch (error) {
          setErrorMessage(t('tickets.errors.generic'));
        }
      }
      setTimeout(() => setDisabledBuy(false), 2000);
    } catch (error) {
      console.log('Error handleBuy', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  };
  const handleHome = (e) => navigate('/');

  useEffect(() => {
    if (cart?.length === 0) {
      navigate('/');
    } else {
      const initialize = async () => {
        const data = {
          products: cart.map((product) => {
            return { id: product.id, count: product.count };
          }),
          code: 'NOCODE',
        };
        const response = await calculate(data);
        if (response.success) {
          setAmount(response.data.credit_card['1']);
          setCalc(response.data);
        }
        const newAttendees = Array.from(
          { length: parseInt(cart[0].count) },
          (_, index) => {
            return {
              name: '',
              email: '',
              cellPhone: '',
              badgename: '',
              speciality: '',
              aceptTerms: false,
              marketingTerms1: false,
              marketingTerms2: false,
              cancelTerms: false,
              complianceTerms: false,
            };
          },
        );
        // console.log('cnpjLogin', cnpjLogin);
        // console.log(newAttendees);
        setAttendees(newAttendees);
      };

      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paid]);

  const checkIfCpfIsRegistered = async (document, index) => {
    try {
      if (document.length !== 14) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: '',
        });
        return;
      }

      let hasCpf = attendees.find((a, i) => { return a.document === document && i !== index });
      if (hasCpf) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: 'Este CPF já está sendo utilizado em outro ingresso',
        });
        return;
      }

      let ret = await transactionService.checkIfCpfIsRegistered({
        document: document,
        eventId: transactionDefault.eventId,
      });

      if (!ret.success) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: 'Este CPF já realizou a compra',
        });
        return;
      }

      setNextButtonControl({
        ...nextButtonControl,
        open: true,
        errorMessage: '',
      });
      return;
    } catch (error) {
      setErrorMessage(t('tickets.errors.generic'));
    }
  }

  const form = (
    <section className='d-flex flex-column justify-content-center align-items-center w-100'>
      <Header />
      <Card>
        <Row className='mt-3'>
          <Col
            md={6}
            className='mb-3'
          >
            <div
              id='event-header'
              className='ps-3 pe-3'
            >
              <Event t={t} />
            </div>
          </Col>
          <Col
            md={6}
            className='mb-2'
          >
            <Cart
              cart={cart}
              amount={amount}
              t={t}
            />
            {/* {step === attendees.length + 1 && step > 1 &&
              ( */}
            <>
              <PromoCode
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                handlePromoCode={handlePromoCode}
                t={t}
              />
            </>
            {/* )} */}
          </Col>
        </Row>
        <CardBody>
          {step > 0 && step <= attendees.length && (
            <>
              <Attendee
                index={step - 1}
                attendee={attendees[step - 1]}
                fillAttendee={fillAttendee}
                length={attendees?.length}
                t={t}
                htmlId={htmlId}
                cnpjLogin={cnpjLogin}
                cpfLogin={cpfLogin}
                checkIfCpfIsRegistered={checkIfCpfIsRegistered}
                nextButtonControl={nextButtonControl}
              />
              <Steps
                textPrev={
                  <>
                    <i className='bi bi-arrow-left me-2'></i>
                    {t('login.buttons.left')}
                  </>
                }
                handlePrev={handlePrev}
                textNext={
                  <>
                    {t('login.buttons.right')}
                    <i className='bi bi-arrow-right ms-2'></i>
                  </>
                }
                handleNext={handleNext}
                nextButtonControl={nextButtonControl}
              />
            </>
          )}
          {step === attendees.length + 1 && step > 1 && (
            <>
              {transaction.paymentType !== FREE && (
                <PaymentOption
                  transaction={transaction}
                  handleCreditCard={handleCreditCard}
                  handlePix={handlePix}
                  t={t}
                />
              )}

              {transaction.paymentType === CREDITCARD && (
                <>
                  <Token
                    cctoken={cctoken}
                    amount={amount}
                    fillToken={fillToken}
                    installments={cart[0].installments}
                    cart={cart}
                    t={t}
                    calc={calc}
                    setAmount={setAmount}
                  />
                </>
              )}

              <Customer
                customer={customer}
                fillCustomer={fillCustomer}
                paymentType={transaction.paymentType}
                t={t}
              />
              <Row>
                <span className='text-danger text-center w-100'>
                  {errorMessage}
                </span>
              </Row>
              {transaction.paymentType === PIX &&
                transactionResponse?.data?.pix && (
                  <PixQrCode
                    qrCode={transactionResponse.data?.pix?.qrcode}
                    qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
                    transactionId={transactionResponse?.data?.transactionId}
                    setPaid={setPaid}
                    remainingTime={remainingTime}
                    t={t}
                  />
                )}

              <Steps
                textPrev={
                  <>
                    <i className='bi bi-arrow-left me-2'></i>
                    {t('login.buttons.left')}
                  </>
                }
                handlePrev={handlePrev}
                textNext={
                  disabledBuy ? (
                    <>{t('tickets.fields.processing')}</>
                  ) : (
                    <>
                      <i className='bi bi-cart me-2'></i>
                      {t('tickets.fields.tobuy')}
                    </>
                  )
                }
                handleNext={handleBuy}
                disableNext={disabledBuy}
                nextButtonControl={nextButtonControl}
              />
            </>
          )}
        </CardBody>
      </Card>
    </section>
  );

  const successMessage = (
    <>
      <section className='d-flex flex-column justify-content-center align-items-center w-100'>
        <Header />
        <Success
          handleAction={handleHome}
          paymentType={transaction.paymentType}
          t={t}
        />
        <section className='d-flex flex-row justify-content-end mt-3 mb-3 gap-1'>
          <button
            type='button'
            className='btn btn-secondary mw-25'
            onClick={() => navigate('/')}
          >
            <>
              <i className='bi bi-arrow-left me-2'></i>
              {t('login.buttons.left')}
            </>
          </button>
        </section>
      </section>
    </>
  );
  return (
    <>
      <Alert
        isOpen={isOpenModal}
        toggle={toggleModal}
        message={message}
        t={t}
      />
      {paid ? successMessage : form}
      <Zendesk />
      <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    </>
  );
}
