import axios from 'axios';
import * as Sentry from '@sentry/react';

const sendTransaction = async (data) => {
  const qty = data?.attendees?.attendees?.length ?? 0;
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/dsworld/transactions`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  try {
    const { data } = await axios(config);
    if (data?.transactionId) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'compra_realizada',
        event_id: data?.transactionId,
        qty: qty,
      });
      return {
        success: true,
        data,
      };
    }
    return data;
  } catch (error) {
    Sentry.setContext('request', { config });
    Sentry.setContext('response', error.response.data);
    Sentry.captureMessage(config.url);
    return { success: false, data: error.response.data };
  }
};

const checkIfCpfIsRegistered = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/dsworld/checkIfCpfIsRegistered`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  try {
    const { data } = await axios(config);

    if (data?.success === false) {
      return { success: false, data: null };
    }
    return { success: true, data: null };
  } catch (error) {
    Sentry.setContext('request', { config });
    Sentry.setContext('response', error.response.data);
    Sentry.captureMessage(config.url);
    return { success: false, data: error.response.data };
  }
};

export { sendTransaction, checkIfCpfIsRegistered };
