import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spinner } from 'reactstrap';
import { emailMask, onDelete } from '../../helpers/masks';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import Header from '../../components/header';
import { Alert } from '../../components/modal';
import Zendesk from '../../components/zendesk';

import { useError } from '../../hooks/useError';

import { TransactionContext } from '../../contexts/TransactionContext';
import { isEmpty } from '../../helpers/validation';

import { getTickets } from '../../services/product';

import { EVENTID } from '../../helpers/constants';

export default function Login() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [associated, setAssociated] = useState(false);
  const [emailLogin, setEmailLogin] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { setNewTickets, firstStep, setCnpjLogin, setCpfLogin, setMaxCount, setEmailLogged } =
    useContext(TransactionContext);
  const { goToFieldWithError } = useError();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    goToFieldWithError('login-document');
  };

  const handleAssociated = async (e) => {
    try {
      setIsLoading(true);

      e.preventDefault();

      if (isEmpty(emailLogin)) {
        return;
      }

      const response = await getTickets({
        email: emailLogin,
        eventId: EVENTID,
      });

      if (!response?.success) {
        let errorMessage = t('login.errors.notassociated');
        if (response === 'USER_ALREADY_BOUGHT') {
          errorMessage = 'Este e-mail já realizou a compra'
        }
        setMessage(errorMessage);
        openModal();
        return;
      }

      const tickets = response?.data?.products?.map((ticket) => ({
        ...ticket,
        count: 0,
      }));

      setNewTickets(tickets);
      setEmailLogged(emailLogin);
      navigate('/carrinho');
    } catch (error) {
      setMessage(t('login.errors.document'));
      openModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotAssociated = async (e) => {
    const response = await getTickets({
      document: '',
      eventId: EVENTID,
    });
    const tickets = response?.products?.map((ticket) => ({
      ...ticket,
      count: 0,
    }));

    setNewTickets(tickets);
    navigate('/carrinho');
  };

  const modal = (
    <Alert
      isOpen={open}
      toggle={closeModal}
      message={message}
    />
  );

  const options = (
    <>
      <div className='container'>
        <div className='d-flex gap-3 justify-content-center col'>
          <button
            className='btn btn-secondary btn-gs1-login'
            onClick={(e) => setAssociated(true)}
          >
            <i className='bi bi-person-vcard-fill me-2' />
            {t('login.buttons.associated')}
          </button>

          <button
            className='btn btn-secondary btn-gs1-login'
            onClick={handleNotAssociated}
          >
            <i className='bi bi-person-fill me-2' />
            {t('login.buttons.notassociated')}
          </button>
        </div>
      </div>
    </>
  );

  const associatedLogin = (
    <>
      <form
        onSubmit={handleAssociated}
        className=''
      >
        <Row className='justify-content-center'>
          <Col className='col-md-3 mb-3'>
            <label
              htmlFor='login-document'
              className='form-label'
            >
              {t('login.fields.email')}
            </label>
            <input
              id='login-document'
              className='form-control'
              type='email'
              autoComplete='off'
              value={emailLogin}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = emailMask(value);
                setEmailLogin(newValue);
              }}
              onKeyDown={(e) => {
                setEmailLogin(emailLogin);
              }}
            />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col className='col-auto mb-3'>
            <button
              type='submit'
              className='btn btn-secondary'
            >
              <i className='bi bi-box-arrow-in-right me-2' />
              {t('login.buttons.enter')}
            </button>
          </Col>
        </Row>
      </form>
    </>
  );

  const loading = (
    <Row className='justify-content-center'>
      <Spinner />
    </Row>
  )

  useEffect(() => {
    document.title = t('title');
    firstStep();
    setCnpjLogin('');
    setCpfLogin('');
    setMaxCount(10);
    setNewTickets([]);
  }, [open, t]);
  return (
    <>
      {modal}
      <div className='container'>
        <Header />
        {/* {!associated && options}
        {associated && associatedLogin} */}
        {isLoading ? loading : associatedLogin}
      </div>
      <Zendesk />
    </>
  );
}
