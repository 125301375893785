import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useError } from '../../hooks/useError';

import { Card, CardBody, Row, Col } from 'reactstrap';

import Header from '../../components/header';
import { AttendeeGroup } from '../../components/attendeeGroup';
import Cart from '../../components/cart';
import Steps from '../../components/steps';
import { Alert } from '../../components/modal';
import Event from '../../components/event';
import { Token } from '../../components/token';
import PromoCode from '../../components/promocode';
import { PixQrCode } from '../../components/pixqrcode';
import PaymentOption from '../../components/paymentOption';
import { Customer } from '../../components/customer';
import * as transactionService from '../../services/transaction';
import Zendesk from '../../components/zendesk';
import { Loading } from '../../components/comon';

import { TransactionContext } from '../../contexts/TransactionContext';

import {
  attendeeDefault,
  transactionDefault,
  customerDefault,
  cctokenDefault,
  transactionResponseDefault,
} from '../../helpers/models';

import { CREDITCARD, FREE, PIX, PIXEXPIRETIME } from '../../helpers/constants';

import { createPaymentToken } from '../../services/cctoken';
import { sendTransaction } from '../../services/transaction';
import { calculate } from '../../services/calculate';
import { emailValidation } from '../../services/emailvalidation';

import Success from '../../components/success';

export default function Payment() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [loadingControl, setLoadingControl] = useState({
    open: false,
    message: 'Carregando',
  });

  const goToTop = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    element.focus();
  };

  const {
    cart,
    nextStep,
    prevStep,
    step,
    remainingTime,
    setRemainingTime,
    cnpjLogin,
    cpfLogin,
    emailLogged,
  } = useContext(TransactionContext);

  const { attendeeError, cctokenError, customerError } = useError();

  const [amount, setAmount] = useState(null);

  const [nextButtonControl, setNextButtonControl] = useState({
    openEmailField: true,
    open: false,
    errorMessage: '',
  });

  const [attendeesCount, setAttendeesCount] = useState(1);
  const [attendees, setAttendees] = useState([]);
  useEffect(() => {
    try {
      getAttendeesList();
    } catch (error) {
      console.log('Error getFirstAttendee', error);
    }

    function getAttendeesList() {
      if (emailLogged && firstAccess.current && attendees[0] && attendees[0].email === '' && cart[0]?.tag.includes('pacote_')) {
        let _count = parseInt(cart[0].tag.split('_')[1]);
        if (attendees.length !== _count) {
          let _attendees = [];
          for (let i = 0; i < _count; i++) {
            _attendees.push({
              ...attendees[0],
              email: i === 0 ? emailLogged : '',
              aceptTerms: i > 0 ? true : false,
              marketingTerms1: i > 0 ? true : false,
              marketingTerms2: i > 0 ? true : false,
              cancelTerms: i > 0 ? true : false,
              complianceTerms: i > 0 ? true : false,
            });
          }
          setAttendees(_attendees);
        }
        setAttendeesCount(_count);
        firstAccess.current = false;
      }
    }
  }, [attendees]);
  const firstAccess = useRef(true);

  useEffect(() => {
    firstAccess.current = true;
  }, []);

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const [transaction, setTransaction] = useState(transactionDefault);
  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const [customer, setCustomer] = useState(customerDefault);
  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const [cctoken, setCctoken] = useState(cctokenDefault);
  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const [htmlId, setHtmlId] = useState('');
  const [message, setMessage] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggleModal = () => {
    setIsOpenModal(false);
    setTimeout(() => {
      goToFieldWithError(htmlId);
    }, 50);
  };
  const openModal = () => setIsOpenModal(true);

  const [disabledBuy, setDisabledBuy] = useState(false);
  const doNothing = () => {
    setDisabledBuy(false);
    return;
  };

  const [paid, setPaid] = useState(false);

  const [transactionResponse, setTransactionResponse] = useState(
    transactionResponseDefault,
  );

  const [errorMessage, setErrorMessage] = useState('');

  const [promoCode, setPromoCode] = useState('');

  const [calc, setCalc] = useState(null);

  const validate = (name, fields, error, model, index = -1) => {
    let response = true;

    if (name === 'attendee') {
      let _count = 0;
      do {
        let _guest = attendees[_count];

        for (let key in _guest) {
          let validated = error[key](model);
          if (validated === false) {
            const id =
              _count > -1 ? `${name}-${_count}-${key}` : `${name}-${key}`;
            const msg = `${name}.errors.${key}`;
            setHtmlId(id);
            setMessage(t(msg));
            openModal();
            response = false;
            break;
          }
        }

        if (!response) {
          _count = attendees.length;
        }

        _count++;
      } while (_count < attendees.length);
    } else {
      for (let field in fields) {
        let validated = error[field](model);
        if (validated === false) {
          const id =
            index > -1 ? `${name}-${index}-${field}` : `${name}-${field}`;
          const msg = `${name}.errors.${field}`;
          setHtmlId(id);
          setMessage(t(msg));
          openModal();
          response = false;
          break;
        }
      }
    }

    return response;
  };

  const handleNext = async (e) => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: 'Carregando' });

      if (step <= attendees.length) {
        let _validated;
        let _hasError = false;
        let _count = 0;
        do {
          const attendeeErrorFields = t('attendee.errors', {
            returnObjects: true,
          });

          _validated = validate(
            'attendee',
            attendeeErrorFields,
            attendeeError,
            attendees[_count],
            _count,
          );

          if (!_validated) {
            _hasError = true;
            _count = attendees.length;
          } else {
            const data = {
              eventId: transaction.eventId,
              email: attendees[_count],
            };

            const _hasEmail = attendees.find((a, i) => {
              return a.email === attendees[_count].email && i !== _count;
            });

            const response = await emailValidation(data);
            if (_hasEmail || !response.success) {
              setHtmlId(`attendee-${_count}-email`);
              setMessage(t('tickets.errors.emailalreadyadded'));
              openModal();
              _hasError = true;
              _count = attendees.length;
            }
          }
          _count++;
        } while (_count < attendees.length);

        if (_hasError) {
          return;
        }

        nextStep(attendees.length);
        goToTop('event-header');
      }
    } catch (error) {
      console.log('Error handleNext', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  };

  const handlePrev = (e) => {
    if (step > 1) {
      if (step > 2) {
        prevStep(attendees.length);
      } else {
        prevStep();
      }
      goToTop('event-header');
    } else {
      navigate('/');
    }
  };

  const handlePix = (e) => {
    setAmount(calc ? calc.pix : amount);
    fillTransaction('paymentType', PIX);
  };
  const handleCreditCard = (e) => {
    setAmount(calc ? calc.credit_card[cctoken.installments] : amount);
    fillTransaction('paymentType', CREDITCARD);
  };
  const handlePromoCode = async (e) => {
    e.preventDefault();
    if (promoCode === '') {
      return;
    }
    const data = {
      products: cart.map((product) => {
        return { id: product.id, count: product.count };
      }),
      code: promoCode,
    };
    const response = await calculate(data);
    if (response.success) {
      setCalc(response.data);
      if (response.data.pix > 0 && response.data.pix !== amount) {
        fillTransaction('promoCode', promoCode);

        if (transaction.paymentType === PIX) {
          setAmount(response.data.pix);
        }
        if (transaction.paymentType === CREDITCARD) {
          setAmount(response.data.credit_card[cctoken.installments]);
        }
        if (transaction.paymentType === FREE) {
          fillTransaction('paymentType', PIX);
        }
      }
      if (response.data.pix === 0) {
        fillTransaction('paymentType', FREE);
        fillTransaction('promoCode', promoCode);
        setAmount(response.data.pix);
      }
    }

    if (response.message === 'PROMO_NOT_EXISTS') {
      setMessage('Este cupom não é válido para este tipo de ingresso');
      openModal();
    }
  };
  const handleBuy = async (e) => {
    try {
      setLoadingControl({ ...loadingControl, open: true, message: 'Salvando' });

      e.preventDefault();
      setErrorMessage('');
      setDisabledBuy(true);
      if (transaction.paymentType === CREDITCARD) {
        const ccTokenErrorFields = t('cctoken.errors', {
          returnObjects: true,
        });
        const validatedCctoken = validate(
          'cctoken',
          ccTokenErrorFields,
          cctokenError,
          cctoken,
        );
        if (!validatedCctoken) {
          return doNothing();
        }
        const tokenResponse = await createPaymentToken(
          transaction.paymentType,
          cctoken,
        );
        if (tokenResponse?.success) {
          transaction.token = tokenResponse.token;
        } else {
          setErrorMessage(tokenResponse.message);
          return doNothing();
        }
      }

      const customerErrorFields = t('customer.errors', {
        returnObjects: true,
      });

      const validatedCustomer = validate(
        'customer',
        customerErrorFields,
        customerError,
        customer,
      );

      if (!validatedCustomer) {
        return doNothing();
      }

      transaction.customer = customer;
      transaction.attendees = attendees;
      transaction.products = cart.map((product) => {
        return { id: product.id, count: product.count };
      });
      transaction.installments =
        transaction.paymentType === CREDITCARD
          ? parseInt(cctoken.installments)
          : 1;

      const response = await sendTransaction(transaction);
      setTransactionResponse(response);

      if (response.success) {
        if (transaction.paymentType === PIX) {
          setRemainingTime(PIXEXPIRETIME);
        }
        if (
          transaction.paymentType === CREDITCARD ||
          transaction.paymentType === FREE
        ) {
          setPaid(true);
        }
      } else {
        try {
          const errorMessages = {
            ALREADY_BOUGHT: t('tickets.errors.alreadybought'),
            PRODUCT_NOT_AVAILABLE: t('tickets.errors.productnotavailable'),
            PROMO_NOT_AVAILABLE: t('tickets.errors.promonotavailable'),
          };
          const newErrorMessage =
            errorMessages[response?.data?.message] || t('tickets.errors.generic');
          setErrorMessage(newErrorMessage);
        } catch (error) {
          setErrorMessage(t('tickets.errors.generic'));
        }
      }
      setTimeout(() => setDisabledBuy(false), 2000);
    } catch (error) {
      console.log('Error handleBuy', error);
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  };
  const handleHome = (e) => navigate('/');

  useEffect(() => {
    if (cart?.length === 0) {
      navigate('/');
    } else {
      const initialize = async () => {
        const data = {
          products: cart.map((product) => {
            return { id: product.id, count: product.count };
          }),
          code: 'NOCODE',
        };
        const response = await calculate(data);
        if (response.success) {
          setAmount(response.data.credit_card['1']);
          setCalc(response.data);
        }
        const newAttendees = Array.from(
          { length: parseInt(cart[0].count) },
          (_, index) => {
            return {
              name: '',
              email: '',
              cellPhone: '',
              badgename: '',
              speciality: '',
              aceptTerms: false,
              marketingTerms1: false,
              marketingTerms2: false,
              cancelTerms: false,
              complianceTerms: false,
            };
          },
        );
        // console.log('cnpjLogin', cnpjLogin);
        // console.log(newAttendees);
        setAttendees(newAttendees);
      };

      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paid]);

  const checkIfCpfIsRegistered = async (document, index) => {
    try {
      if (document.length !== 14) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: '',
        });
        return;
      }

      let hasCpf = attendees.find((a, i) => { return a.document === document && i !== index });
      if (hasCpf) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: 'Este CPF já está sendo utilizado em outro ingresso',
        });
        return;
      }

      let ret = await transactionService.checkIfCpfIsRegistered({
        document: document,
        eventId: transactionDefault.eventId,
      });

      if (!ret.success) {
        setNextButtonControl({
          ...nextButtonControl,
          open: false,
          errorMessage: 'Este CPF já realizou a compra',
        });
        return;
      }

      setNextButtonControl({
        ...nextButtonControl,
        open: true,
        errorMessage: '',
      });
      return;
    } catch (error) {
      setErrorMessage(t('tickets.errors.generic'));
    }
  }

  const form = (
    <section className='d-flex flex-column justify-content-center align-items-center w-100'>
      <Header />
      <Card>
        <Row className='mt-3'>
          <Col
            md={6}
            className='mb-3'
          >
            <div
              id='event-header'
              className='ps-3 pe-3'
            >
              <Event t={t} />
            </div>
          </Col>
          <Col
            md={6}
            className='mb-2'
          >
            <Cart
              cart={cart}
              amount={amount}
              t={t}
            />
            {/* {step === attendees.length + 1 && step > 1 &&
              ( */}
            <>
              <PromoCode
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                handlePromoCode={handlePromoCode}
                t={t}
              />
            </>
            {/* )} */}
          </Col>
        </Row>
        <CardBody>
          {step > 0 && step <= attendees.length && (
            <>
              <AttendeeGroup
                attendeesCount={attendeesCount}
                index={step - 1}
                attendees={attendees}
                fillAttendee={fillAttendee}
                length={attendees?.length}
                t={t}
                htmlId={htmlId}
                cnpjLogin={cnpjLogin}
                cpfLogin={cpfLogin}
                checkIfCpfIsRegistered={checkIfCpfIsRegistered}
                nextButtonControl={nextButtonControl}
              />
              <Steps
                textPrev={
                  <>
                    <i className='bi bi-arrow-left me-2'></i>
                    {t('login.buttons.left')}
                  </>
                }
                handlePrev={handlePrev}
                textNext={
                  <>
                    {t('login.buttons.right')}
                    <i className='bi bi-arrow-right ms-2'></i>
                  </>
                }
                handleNext={handleNext}
                nextButtonControl={nextButtonControl}
              />
            </>
          )}
          {step === attendees.length + 1 && step > 1 && (
            <>
              {transaction.paymentType !== FREE && (
                <PaymentOption
                  transaction={transaction}
                  handleCreditCard={handleCreditCard}
                  handlePix={handlePix}
                  t={t}
                />
              )}

              {transaction.paymentType === CREDITCARD && (
                <>
                  <Token
                    cctoken={cctoken}
                    amount={amount}
                    fillToken={fillToken}
                    installments={cart[0].installments}
                    cart={cart}
                    t={t}
                    calc={calc}
                    setAmount={setAmount}
                  />
                </>
              )}

              <Customer
                customer={customer}
                fillCustomer={fillCustomer}
                paymentType={transaction.paymentType}
                t={t}
              />
              <Row>
                <span className='text-danger text-center w-100'>
                  {errorMessage}
                </span>
              </Row>
              {transaction.paymentType === PIX &&
                transactionResponse?.data?.pix && (
                  <PixQrCode
                    qrCode={transactionResponse.data?.pix?.qrcode}
                    qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
                    transactionId={transactionResponse?.data?.transactionId}
                    setPaid={setPaid}
                    remainingTime={remainingTime}
                    t={t}
                  />
                )}

              <Steps
                textPrev={
                  <>
                    <i className='bi bi-arrow-left me-2'></i>
                    {t('login.buttons.left')}
                  </>
                }
                handlePrev={handlePrev}
                textNext={
                  disabledBuy ? (
                    <>{t('tickets.fields.processing')}</>
                  ) : (
                    <>
                      <i className='bi bi-cart me-2'></i>
                      {t('tickets.fields.tobuy')}
                    </>
                  )
                }
                handleNext={handleBuy}
                disableNext={disabledBuy}
                nextButtonControl={nextButtonControl}
              />
            </>
          )}
        </CardBody>
      </Card>
    </section>
  );

  const successMessage = (
    <>
      <section className='d-flex flex-column justify-content-center align-items-center w-100'>
        <Header />
        <Success
          handleAction={handleHome}
          paymentType={transaction.paymentType}
          t={t}
        />
        <section className='d-flex flex-row justify-content-end mt-3 mb-3 gap-1'>
          <button
            type='button'
            className='btn btn-secondary mw-25'
            onClick={() => navigate('/')}
          >
            <>
              <i className='bi bi-arrow-left me-2'></i>
              {t('login.buttons.left')}
            </>
          </button>
        </section>
      </section>
    </>
  );
  return (
    <>
      <Alert
        isOpen={isOpenModal}
        toggle={toggleModal}
        message={message}
        t={t}
      />
      {paid ? successMessage : form}
      <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
      <Zendesk />
    </>
  );
}
